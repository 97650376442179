// third-party
import { combineReducers } from "redux";

// project import

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({});

export default reducers;
