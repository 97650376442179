import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Header from "./Header";

// ==============================|| MINIMAL LAYOUT ||============================== //

const SiteLayout = () => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header></Header>
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1 }}
        alignItems="center"
      >
        <Toolbar></Toolbar>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SiteLayout;
